:root {
  --deep-pink: #FF0063;
  --light-pink: #ff00622a;
  --gold: #ffe000;
  --gold-transparent: #ffe10030;
  --gold-use: #e0c600;
  --gold-pre-use: #edd100;
  --white: white;
  --black: black;
  --valid-green: rgba(0, 180, 0, 0.259);
  --waiting-yellow: rgba(162, 180, 0, 0.259);
  --police-titre: 'Roboto', Arial, sans-serif;
}

@font-face {
  font-family: 'GtSuper-Super-Bold';
  src: url('/public/fonts/GT-Super-Display-Super-Trial.otf') format('opentype')
}

@font-face {
  font-family: 'GtSuper-Bold-important';
  src: url('/public/fonts/GT-Super-Display-Bold-Trial.otf') format('opentype')
}

@font-face {
  font-family: 'GtSuper-medium';
  src: url('/public/fonts/GT-Super-Display-Medium.woff2') format('woff2')
}

@font-face {
  font-family: 'GtSuper-bold';
  src: url('/public/fonts/GT-Super-Display-Bold.woff2') format('woff2')
}

@font-face {
  font-family: 'GtSuper';
  src: url('/public/fonts/GT-Super-Display-Regular-Trial.otf') format('opentype')
}

@font-face {
  font-family: 'GtSuper-Light';
  src: url('/public/fonts/GT-Super-Display-Light-Trial.otf') format('opentype')
}

@font-face {
  font-family: 'myriad-regular';
  src: url('/public/fonts/MYRIADPRO-REGULAR.woff') format('woff')
}

@font-face {
  font-family: 'myriad-semibold';
  src: url('/public/fonts/MYRIADPRO-SEMIBOLD.woff') format('woff')
}

@font-face {
  font-family: 'myriad-bold';
  src: url('/public/fonts/MYRIADPRO-BOLD.woff') format('woff')
}

@font-face {
  font-family: 'Akzid-Reg';
  src: url('/public/fonts/AkzidGroBQReg.otf') format('opentype')
}

@font-face {
  font-family: 'Akzid-Bold';
  src: url('/public/fonts/AkzidGroBQBol.otf') format('opentype')
}

@font-face {
  font-family: 'Akzid-Medium';
  src: url('/public/fonts/AkzidGroBQMd.otf') format('opentype')
}

@font-face {
  font-family: 'Akzid-Light';
  src: url('/public/fonts/AkzidGroBQLig.otf') format('opentype')
}

body {
  padding: 0;
  margin: 0;
}

body {
  padding: 0; 
  margin: 0;
  font-family: 'myriad-regular';
  background-color: #F5F5F5;
}

a {
  text-decoration: none;
  color: inherit;
}

.main {
  width: 100%;
  height: 90vh;
}
.progress-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10vh;
}

.progress-bar .sub {
  margin: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.super.global {
  width: calc(100% - 200px - 80px);
  margin: 0 auto 60px auto;
  background-color: white;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  position: relative;
  padding: 0 40px 40px 40px;
  max-width: 1100px;
  overflow: hidden;
}

.cta {
  color: white;
  background-color: #FF0063;
  box-shadow: 0 0px 9px 1px var(--deep-pink);
  padding: 16px 48px;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  text-transform: uppercase;
  cursor: pointer;
}

.self-step .global .answers > .cta, .self-step .global .answers > a > .cta {
  margin: 40px auto 20px auto;
}

.self-step {
  display: block;
  margin: 50px auto;
  text-align: center;
}

.self-step .global .questions { 
  font-size: 22pt;
  font-family: 'GtSuper-medium';
  max-width: 850px;
  margin: 0 auto;
}

.self-step > .global > .description {
  font-size: 11pt;
  color: #979797;
  padding: 25px 0 50px 0;
}

.self-step .global .questions span.important {
  text-decoration: underline;
}

.self-step .global .answers {
  width: 100%;
  margin: 60px auto;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.self-step .global .answers .answer input {
  width: calc(100% - 80px);
  max-width: 330px;
  border: 2px solid #d8d8d8;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  padding: 13px 11px;
}

.self-step .global .answers .multiform {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 30px;
}

.self-step .global .answers .multiform .reinsurance.important {
  background-color: #ff00621c;
  padding: 3px 14px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  font-size: 10pt;
  margin: 0 auto 5px auto;
  width: max-content;
  color: var(--deep-pink);
  position: absolute;
  top: -38px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.self-step .global .answers .multiform a {
  display: flex;
}

.self-step .global .answers .multiform .big-button {
  cursor: pointer;
  margin: 0 15px;
  font-family: 'Akzid-Reg';
  padding: 45px;
  border: 2px solid var(--deep-pink);
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  width: calc(300px - 90px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  transition: box-shadow 0.2s ease-in-out;
  -webkit-transition: box-shadow 0.2s ease-in-out;
  -moz-transition: box-shadow 0.2s ease-in-out;
  -ms-transition: box-shadow 0.2s ease-in-out;
  -o-transition: box-shadow 0.2s ease-in-out;
}

.self-step .global .answers .multiform .big-button.pre-choice {
  box-shadow: 0 0px 9px 1px var(--deep-pink);
}

@media not all and (pointer: coarse) {
  .self-step .global .answers .multiform .big-button:hover {
    box-shadow: 0 0px 9px 1px var(--deep-pink);
  }
}

.self-step .global .answers .multiform .big-button .emoji {
  font-size: 28pt;
}
.self-step .global .answers .multiform .big-button .affirmation {
  font-size: 15pt;
  font-family: 'Akzid-Medium';
}
.self-step .global .answers .multiform .big-button .description {
  font-size: 16pt;
}
.self-step .global .answers .multiform .big-button .subtitle {
  font-size: 12pt;
  margin-top: 28px;
  color: #979797;
}

.self-step .global .answers .later {
  margin-top: 20px;
}

.self-step .global .later {
  color: var(--deep-pink);
  font-size: 10pt;
}

.self-step .global .answers .description {
  color: black;
  font-size: 10pt;
}

.self-step .global .later:hover {
  text-decoration: underline;
  cursor: pointer;
}

.main-19.self-step .global > .details {
  margin-top: 60px;
}

.self-step .global .main-details {
  margin-bottom: 70px;
}

.self-step .global > .details {
  font-family: 'Akzid-Reg';
  background-color: #ffdd003d;
  margin: 0 auto 30px auto;
  width: calc(100% - 80px);
  padding: 5px 15px 10px 15px;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  max-width: 630px;
  font-size: 10pt;
}

.self-step .global > .sub_details {
  color: #979797;
  font-size: 10pt;
  width: calc(100% - 80px);
  margin: 0 auto;
}

.self-step .global > .details .bold {
  font-family: 'Akzid-Medium';
}

.self-step .global .actions {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 20px;
  left: 0px;
  width: 100%;
  color: #979797;
}

.self-step .global .actions .prev {
  margin-left: 25px;
  font-size: 12pt;
  font-family: 'Akzid-Reg';
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.self-step .global .actions .prev .arrow {
  width: 9px;
  height: 9px;
  border-left: 1px solid #979797;
  border-bottom: 1px solid #979797;
  transform: rotateZ(45deg) translateY(6px);
  -webkit-transform: rotateZ(45deg) translateY(6px);
  -moz-transform: rotateZ(45deg) translateY(6px);
  -ms-transform: rotateZ(45deg) translateY(6px);
  -o-transform: rotateZ(45deg) translateY(6px);
  margin-right: 5px;
}

.self-step .global .actions .next {
  margin-right: 20px;
  font-size: 12pt;
  font-family: 'Akzid-Reg';
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  padding: 15px 50px;
  background-color: var(--deep-pink);
  color: white;
  box-shadow: 0 0px 9px 1px var(--deep-pink);
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  cursor: pointer;
}

.self-step .global .question.overlay {
  font-family: 'Akzid-Medium';
  background-color: var(--light-pink);
  color: var(--deep-pink);
  width: 16px;
  height: 16px;
  font-size: 10pt;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-left: 4px;
  cursor: pointer;
  z-index: 2;
  display: inline-block;
  position: relative;

}

.self-step .global .question.overlay .answer {
  display: none;
  position: absolute;
  font-family: 'Akzid-Reg';
  top: 26px;
  font-size: 10pt;
  left: calc(-100% + 30px);
  width: 300px;
  padding: 20px 40px;
  background-color: #FFFFFF;
  border-radius: 40px;
  box-shadow: 0 0 16px -13px #000;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  color: #979797;
  z-index: 3;
}

.self-step .global .question.overlay:hover .answer {
  display: flex;
  flex-direction: column;
}

@media (max-width: 900px) {
  .super.global {
    width: calc(100% - 40px - 80px);
  }
}

@media (max-width: 550px) {
  .self-step .global .answers .multiform .big-button {
    padding: 20px 45px;
  }

  .self-step {
    margin: 25px auto;
  }  
}

@media (max-width: 500px) {
  .super.global {
    padding: 0 15px 40px 15px;
    width: calc(100% - 40px - 30px);

  }
}

@media (max-width: 450px) {
  .super.global {
    width: calc(100% - 30px);
  }
  .self-step .global .actions .next {
    font-size: 10pt;
    padding: 10px 25px;
  }
}

@media (max-width: 800px) {
  /* .multiform {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  .big-button {
    padding: 10px 20px !important;
    width: calc(50% - 90px) !important;
  }
  .big-button .emoji {
    font-size: 12pt !important;
  }
  .big-button .affirmation {
    font-size: 13pt !important;
  }
  .big-button .description {
    font-size: 12pt !important;
  }
  .big-button .subtitle {
    font-size: 11pt !important;
  } */
}

.prev_url {
  width: calc(100% - 40px);
  height: auto;
  text-align: center;
  background-color: var(--gold);
  display: flex;
  row-gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
}

.prev_url .prev_paragraphe {
  margin-right: 15px;
}

.prev_url .prev_button {
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid var(--deep-pink);
  background-color: var(--deep-pink);
  box-shadow: 0 0px 9px 1px var(--deep-pink);
  color: white;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}