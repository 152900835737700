.our-story .main-value {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: 'Akzid-Reg';
    font-size: 10pt;
    max-width: 640px;
    margin: 0 auto;
}

.our-story .main-value .value h2 {
    font-family: 'GtSuper-medium';
    font-size: 15pt;
}

.our-story .value {
    margin: 3px 0;
    padding: 20px;
    border: 2px solid #D8D8D8;
    max-width: 273px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    background-color: #FFFFFF;
}

.our-story .value.satisfaction {
    max-width: 100%;
    width: 100%;
}

.our-story .value div.title {
    margin: 20px 0;
}

.our-story .value div.description .bold {
    font-family: 'Akzid-Medium';
}

.our-story .value .icon {
    font-size: 20pt;
}

.our-story .didier-presentation {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 30px;
}

.our-story .didier-presentation img.fst {
    width: 80%;
}

.our-story .didier-presentation img.scd {
    display: none;
    width: 100%;
}

.our-story .main-value .left {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.our-story .main-value .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

@media (max-width: 753px) {
    .our-story .main-value {
        width: 100%;
        max-width: 100%;
        flex-direction: column;
    }
    .our-story .value {
        width: 100%;
        max-width: calc(100% - 45px - 0px) !important;
    }
}

@media (max-width: 485px) {
    .our-story .didier-presentation img.fst {
        width: 100%;
        margin-bottom: 30px;
    }
}

@media (max-width: 400px) { 
    .our-story .didier-presentation img.fst {
        display: none;
    }
    .our-story .didier-presentation img.scd {
        display: block;
        margin-bottom: 30px;
    }
}