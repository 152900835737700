.self-step .global .answers {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.self-step .global .answer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.main-7 .global .answer:not(:first-child) {
    margin-top: 30px;
}

.self-step .global .answer .label {
    margin-left: 20px;
    margin-bottom: 5px;
    font-family: 'Akzid-Medium';
    white-space: nowrap;
}

.main-7 .global .answer select {
    color: #FF0063;
    border: 1px solid #D8D8D8;
    border-radius: 10px;
    width: 100%;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 13px 11px;
    -webkit-appearance: none;
    appearance: none;
    /* border: none; */
    background-image: url('/public/icon/arrow-down.svg');
    background-repeat: no-repeat;
    background-size: 12px 6px;
    background-position: calc(100% - 16px);
}

.main-7 .global .answers .list {
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 50px 40px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    max-width: 400px;
}

.main-7 .global .answers .divider {
    width: 10%;
    height: 4px;
    position: relative;
    transform: translateY(-100px);
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    -o-transform: translateY(-100px);
}

.main-7 .global .answers .divider .line-divider {
    width: 118px;
    height: 4px;
    position: absolute;
    background-image: radial-gradient(circle, #979797 20%, transparent 20%), linear-gradient(to bottom, transparent 50%, #979797 50%);
    background-size: 7px 12px, 100% 0px;
    background-position: left, center;
    background-repeat: repeat-x;
}

.main-7 .global .answers .result {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-7 .global .answers .result .label {
    background-color: #ff00622d;
    width: max-content;
    color: #FF0063;
    padding: 4px 14px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    margin-bottom: 10px;
}

.main-7 .global .answers .result .final {
    width: 333px;
    text-align: center;
    margin-bottom: 22px;
    border: 2px solid #FF0063;
    background-color: white;
    z-index: 99999;
    padding: 18px 0;
    font-family: 'Akzid-Medium';
    font-size: 20pt;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.main-7 .global .answers .cta {
    color: white;
    background-color: #FF0063;
    box-shadow: 0 0px 9px 1px var(--deep-pink);
    padding: 16px 48px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    text-transform: uppercase;
    cursor: pointer;
}

.main-7 .global .answers .result .details {
    max-width: 350px;
    margin-top: 3px;
    font-family: 'Akzid-Reg';
    padding: 2px 8px;
    font-size: 11pt;
}

.main-7 .global .answers .result .details:not(:last-child) {
    margin: 3px auto;
}

.main-7 .global .answers .result .main-details {
    margin-top: 60px;
}

.main-7 .global .actions .next, .main-9 .global .actions .next {
    display: none;
}

.self-step .global .answers .answer input {
    width: 330px;
    border: 2px solid #D8D8D8;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    padding: 13px 11px;
}

.main-12.self-step .global .answers .answer input {
    margin-bottom: 30px;
}


.main-9.self-step .global .answers .list .connection .answers-row input {
    width: 330px;
    border: 2px solid #D8D8D8;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    padding: 13px 11px;
    -webkit-appearance: none;
    appearance: none;
    background-image:url('https://api.comptastar.fr/img/cross.svg');
    background-repeat: no-repeat;
    background-size: 11px 11px;
    background-position: calc(100% - 16px);
}

.main-9.self-step .global .answers .list .connection .answers-row input.active {
    border: 2px solid #6FC467;
    background-image:url('https://api.comptastar.fr/img/valid.svg');
    background-size: 13px 11.5px;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #979797;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #979797;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #979797;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #979797;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    #979797;
}

::placeholder { /* Most modern browsers support this now. */
   color:    #979797;
}

.main-9.self-step .global .answers .list {
    width: 100%;
}

.self-step .global .answers .list .connection .answers-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.main-9.self-step .global .answers .answers-row:last-child {
    margin-top: 30px;
}

.main-9.self-step .global .answers .answers-row .label .required {
    color: var(--deep-pink)
}

.main-9.self-step .global .answer {
    width: auto;
    margin: 0 15px;
}

.main-9.self-step .global .answers .cta, .main-13.self-step .global .answers .cta, .main-14.self-step .global .answers .cta {
    width: max-content;
    margin: 40px auto 20px auto;
}

.main-9.self-step .global .answers .spliter {
    width: 100%;
    max-width: 742px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}

.main-9.self-step .global .answers .spliter .label {
    margin: 0 12px;
    color: #D8D8D8;
    transform: translateY(2px);
    -webkit-transform: translateY(2px);
    -moz-transform: translateY(2px);
    -ms-transform: translateY(2px);
    -o-transform: translateY(2px);

}

.main-9.self-step .global .answers .spliter .line {
    width: calc(50% - 21px);
    height: 2px;
    border-bottom: 2px solid #D8D8D8;
}

.google-sign_in {
    display: flex;
    flex-direction: row;
    padding: 21px 20px;
    border: 2px solid #D8D8D8;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    margin-top: 30px;
    font-family: 'Akzid-Medium';
    cursor: pointer;
}

.google-sign_in:hover {
    background-color: #f7f7f7;
}

.google-sign_in img {
    width: 19px;
    height: 19px;
    margin-right: 15px;
}

.main-12.self-step .global .answers .list .connection .answers-row input {
    width: 330px;
    border: 2px solid #D8D8D8;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    padding: 13px 11px;
    -webkit-appearance: none;
    appearance: none;
    background-image:url('https://api.comptastar.fr/img/cross.svg');
    background-repeat: no-repeat;
    background-size: 11px 11px;
    background-position: calc(100% - 16px);
}

.main-14.self-step .global .answers .multiform .big-button {
    padding: 20px 40px;
    width: calc(343px - 90px);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    position: relative;
}

.main-14.self-step .global .answers .multiform .big-button input.form-login {
    width: calc(343px - 32px);
    height: calc(68px - 26px - 4px);
    max-height: 68px;
    border: 2px solid #D8D8D8;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 13px 11px;
    position: absolute;
    bottom: calc(-100% + 26px - 20px - 22px);
}

.main-17.self-step .global .answers .cta, .main-18.self-step .global .answers .cta {
    margin-top: 50px;
}

.main-17.self-step .global .answers .bullet-points {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.main-17.self-step .global .answers .bullet-points .bullet-point {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    padding: 0 20px;
}

.main-17.self-step .global .answers .bullet-points .bullet-point .emoji {
    font-size: 24pt;
}

.main-17.self-step .global .answers .bullet-points .bullet-point .affirmation {
    font-size: 14pt;
    font-family: 'Akzid-Medium';
    padding: 14px 0 20px 0;
}

.main-17.self-step .global .answers .bullet-points .bullet-point .description {
    font-size: 11pt;
    font-family: 'Akzid-Reg';
}

.main-18.self-step .global .answers .multiform .big-button {
    position: relative;
}

.main-19.self-step .global .sub-details {
    font-style: italic;
}

.main-19.self-step .global .accounting_contract {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
}

.main-19.self-step .global .accounting_contract .formula {
    padding: 16px 30px;
    border: 2px solid #D8D8D8;
    color: #979797;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    position: relative;
    font-family: 'Akzid-Reg';
    cursor: pointer;
}

.main-19.self-step .global .accounting_contract .formula.pre-choice {
    border: 2px solid #FFDF00;
    color: black;
    box-shadow: 0 0px 9px 1px #FFDF00;
}

.main-19.self-step .global .accounting_contract .formula .reinsurance.important {
    color: var(--deep-pink);
    background-color: #ff006224;
    position: absolute;
    top: -35px;
    left: 50%;
    font-size: 9pt;
    white-space: nowrap;
    font-family: 'Akzid-Reg';
    padding: 4px 14px;
    border-radius: 40px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}

.main-19.self-step .global .accounting_contract .formula .primary {
    font-family: 'Akzid-Medium';
}

.main-19.self-step > .global > .accounting_contract > div {
    padding: 0 15px;
}

.main-19.self-step .global .offer-detail {
    margin: 40px auto;
    display: flex;
    flex-direction: column;
}

.main-19.self-step .global .offer-detail .description .exhibitor {
    vertical-align: super;
    font-size: 9pt;
    margin-right: 4px;
}

.main-19.self-step .global .offer-detail .title {
    font-family: 'Akzid-Medium';
}

.main-19.self-step .global .offer-detail .description {
    font-family: 'Akzid-Reg';
    display: flex;
    align-items: center;
    justify-content: center;
}

.self-step .global .question-box {
    position: relative;
    display: inline-block;
    z-index: 5;
}

.self-step .global .description .question-box {
z-index: 4;
}

.self-step .global .question.overlay {
    font-family: 'Akzid-Medium';
    background-color: var(--light-pink);
    color: var(--deep-pink);
    width: 16px;
    height: 16px;
    font-size: 10pt;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin-left: 4px;
    cursor: pointer;
    z-index: 2;
    display: inline-block;
    position: relative;

}

.self-step .global .question.overlay .answer {
    display: none;
    position: absolute;
    font-family: 'Akzid-Reg';
    top: 26px;
    font-size: 10pt;
    left: calc(-100% + 30px);
    width: 300px;
    padding: 20px 40px;
    background-color: #FFFFFF;
    border-radius: 40px;
    box-shadow: 0 0 16px -13px #000;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    color: #979797;
    z-index: 3;
}

.self-step .global .question.overlay:hover .answer {
    display: flex;
}

.main-21.self-step .global .offer .offer-main .offer-box .price .exhibitor {
    vertical-align: super;
    font-size: 11pt;
    margin-right: 4px;
}

@media (max-width: 950px) {
    .self-step .global .answers {
        flex-direction: column;
    }
    .main-7 .global .answers .divider {
        height: 70px;
        width: 4px;
        position: relative;
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
}
    
    .main-7 .global .answers .divider .line-divider {
        height: 70px;
        width: 4px;
        position: absolute;
        background-image: radial-gradient(circle, #979797 20%, transparent 20%), linear-gradient(to bottom, transparent 50%, #979797 50%);
        background-size: 4px 6px, 100% 0px;
        background-position: left, center;
        background-repeat: repeat-y;
    }
}

@media (max-width: 365px) {
    .main-7 .global .answers .result .final {
        font-size: 17pt;
        width: calc(100% - 20px);
    }   
}

@media (max-width: 360px) {
    .main-7 .global .answers .result .details {
        font-size: 9pt;
    }
}
