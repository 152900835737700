.answers {
    margin-bottom: 30px !important;
}

.answers .bullet-points {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.answers .bullet-points .bullet-point {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    padding: 0 20px;
    width: 33%;
}

.answers .bullet-points .bullet-point .emoji {
    font-size: 24pt;
}

.answers .bullet-points .bullet-point .affirmation {
    font-size: 14pt;
    font-family: 'Akzid-Medium';
    padding: 14px 0 20px 0;
}

.answers .bullet-points .bullet-point .description {
    font-size: 11pt;
    font-family: 'Akzid-Reg';
}

.service-choice {
    margin: 40px auto 20px auto;
}

@media (max-width: 600px) {
    .answers .bullet-points {
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
    }
    .answers .bullet-points .bullet-point {
        width: calc(100%);
    }
}