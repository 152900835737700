.global > .details {
    margin-top: 60px;
    font-family: 'Akzid-Reg';
    background-color: #ffdd003d;
    margin: 30px auto 30px auto;
    max-width: max-content;
    padding: 5px 15px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    width: calc(100% - 80px);
    font-size: 10pt;
}

.global .sub-details {
    font-style: italic;
    font-size: 10pt;
    font-family: Akzid-Reg;
    margin: 0 auto 30px auto;
    max-width: 630px;
    color: #979797;
}

.global .sub-details .arrow::before {
    content: '';    
    display: inline-flex;
    width: 15px;
    height: 10px;
    margin-right: 5px;
    background: url('/public/icon/arrow.svg') no-repeat center center;
    background-size: contain;
}



.global .accounting_contract {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
}

.global .accounting_contract .formula {
    padding: 16px 30px;
    border: 2px solid #D8D8D8;
    color: #979797;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    position: relative;
    font-family: 'Akzid-Reg';
    cursor: pointer;
}

.global .accounting_contract .formula.pre-choice {
    border: 2px solid #FFDF00;
    color: black;
    box-shadow: 0 0px 9px 1px #FFDF00;
}

.global .accounting_contract .formula .reinsurance.important {
    color: var(--deep-pink);
    background-color: #ff006224;
    position: absolute;
    top: -35px;
    left: 50%;
    font-size: 9pt;
    white-space: nowrap;
    font-family: 'Akzid-Reg';
    padding: 4px 14px;
    border-radius: 40px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}

.global .accounting_contract .formula .primary {
    font-family: 'Akzid-Medium';
}

.global > .accounting_contract > div {
    padding: 0 15px;
}

.global .offer-detail {
    margin: 40px auto;
    display: flex;
    flex-direction: column;
}

.global .offer-detail .description .exhibitor {
    vertical-align: super;
    font-size: 9pt;
    margin-right: 4px;
}

.global .offer-detail .title {
    font-family: 'Akzid-Medium';
}

.global .offer-detail .description {
    font-family: 'Akzid-Reg';
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: baseline;
}

.global .offer-detail .description > div {
    margin-left: 3px;
}

.button {
    margin: 0 auto !important;
}

@media (max-width: 500px) {
    .global .accounting_contract {
        flex-direction: column-reverse;
    }
    .global .accounting_contract div:nth-child(2) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .global .accounting_contract .formula .reinsurance.important {
        top: -30px;
    }
}