.label {
    position: relative;
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 7px;
}

.label input {
    opacity: 0;
    width: 0;
    height: 0;
}

.label span {
    position: absolute;
    top: 0;
    left: 0;
    width: 13px;
    height: 13px;
    background-color: white;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.label input[type="checkbox"]:checked + span {
    background-color: var(--deep-pink);
    border-color: var(--deep-pink);
}

.label span::after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 0px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.label input[type="checkbox"]:checked + span::after {
    display: block;
}