.legal_name {
    color: var(--deep-pink);
}

.global-summary {
    position: relative;
    max-width: 830px;
    margin: 0 auto;
}

.global-summary .summary.timeline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 60px;
    position: relative;
    z-index: 1;
    column-gap: 10px;
}

.global-summary .divider {
    width: calc(100% - 200px);
    height: 1px;
    position: absolute;
    top: 33px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    z-index: 0;
    height: 4px;
    background-image: radial-gradient(circle, #979797 20%, transparent 20%), linear-gradient(to bottom, transparent 50%, #979797 50%);
    background-size: 4px 6px,100% 0px;
    background-position: left, center;
    background-repeat: repeat-x;
}

.global-summary .summary.timeline .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
}

.global-summary .summary.timeline .step .bullet {
    background-color: #F5F5F5;
    color: #D8D8D8;
    font-family: 'GtSuper-medium';
    font-size: 27pt;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    min-height: 70px;
    min-width: 70px;
}

.global-summary .summary.timeline .step.active .bullet {
    background-color: #fbf4c5;
    color: #FFDF00;
}

.global-summary .summary.timeline .step .bullet.special div {
    font-size: 25pt;
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
}

.global-summary .summary.timeline .step .title {
    font-family: 'Akzid-Medium';
    font-size: 13pt;
    margin: 30px 0 20px 0;
}

.global-summary .summary.timeline .step .description {
    font-family: 'Akzid-Reg';
    color: #979797;
    font-size: 11pt;
}

.global-summary .summary.timeline .step .step_description {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-direction: column;
}

@media (max-width: 650px) {
    .global-summary {
        display: flex;
        align-items: center;
        margin-top: 40px;
    }
    .global-summary .divider {
        left: 34px;
        top: 55px;
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        width: 4px;
        height: calc(100% - 110px);
        background-repeat: repeat-y;
    }
    .global-summary .summary.timeline {
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
    }
    .global-summary .summary.timeline .step {
        flex-direction: row;
        width: 100%;
        column-gap: 20px;
        min-height: 90px;
        padding: 10px 0
    }
    .global-summary .summary.timeline .step .step_description:not(.text-description) {
        flex-direction: row;
        align-items: baseline;
    }
    .global-summary .summary.timeline .step .step_description.text_description {
        text-align: left;
        flex-direction: column;
        position: relative;
        width: 100%;
    }
    .global-summary .summary.timeline .step .step_description.text_description .description {
        position: absolute;
        width: 100%;
        max-width: 400px;
        bottom: -30px;
    }
    .global-summary .summary.timeline .step .step_description:not(.text_description) .description {
        transform: translateY(2px);
        -webkit-transform: translateY(2px);
        -moz-transform: translateY(2px);
        -ms-transform: translateY(2px);
        -o-transform: translateY(2px);
}
}

@media (max-width: 411px) {
    .global-summary .summary.timeline .step:nth-child(3) .step_description.text_description .description {
        bottom: -46px;
    }
}

@media (max-width: 387px) {
    .global-summary .summary.timeline .step:nth-child(4) .step_description.text_description .description {
        bottom: -50px;
    }
}

@media (max-width: 412px) and (min-width: 386px) {
    .global-summary .summary.timeline .step .step_description.text_description .description {
        /* bottom: -37px; */
    }
}