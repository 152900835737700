.questions {
    padding: 70px 0 60px 0;
}

.complementary-information {
    margin-bottom: 75px;
}
.complementary-information .activity-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.complementary-information .activity-list {
    margin: 0 7px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(50% - 100px);
}

.complementary-information .activity-list .activity {
    width: calc(100% - 100px);
    padding: 40px 50px;
    border: solid 2px #D8D8D8;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    text-align: left;
}

.complementary-information .activity-list .activity .title {
    font-family: 'Akzid-Medium';
    color: #121519;
}

.complementary-information .activity-list .activity .title .important {
    color: var(--deep-pink);
}

.complementary-information .activity-list .activity ul {
    font-size: 10pt;
    color: #979797;
    padding-left: 14px;
    margin-bottom: 0;
}

@media (max-width: 785px) {
    .complementary-information .activity-main {
        flex-direction: column;
        align-items: center;
    }
    .complementary-information .activity-main .activity-list {
        width: calc(100% - 30px);
        margin-bottom: 10px;
    }
    .complementary-information .activity-list .activity {
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
}
}