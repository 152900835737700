.progress-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 80px;
}

.progress-bar .sub {
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.progress-bar .sub, .progress-bar .sub .step {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #979797;
}

.progress-bar .sub .step.active, .progress-bar .sub .step.actual {
    color: var(--deep-pink);
    font-family: 'GtSuper';
}

.progress-bar .sub .step.notLastActive .label, .progress-bar .sub .step.step.notLastActive .icon {
    opacity: 0.3;
}

.progress-bar .sub .step.active .label {
    font-family: 'GtSuper-bold';
}

.progress-bar .sub .step .icon {
    display: none;
}

.progress-bar .sub .step.actual {
    font-family: 'GtSuper-bold';
}

.progress-bar .sub .divider {
    margin: 0 12px;
    width: 97px;
    height: 4px;
    background-image: radial-gradient(circle, #979797 20%, transparent 20%), linear-gradient(to bottom, transparent 50%, #979797 50%);
    background-size: 4px 6px,100% 0px;
    background-position: left, center;
    background-repeat: repeat-x;
}

.progress-bar .sub .step.active .divider {
    background-image: radial-gradient(circle, var(--deep-pink) 20%, transparent 20%), linear-gradient(to bottom, transparent 50%, var(--deep-pink) 50%);
}

@media (max-width: 900px) {
    .progress-bar .sub .step .label {
        display: none;
    }
    .progress-bar .sub .step .icon {
        display: block;
    }
    .progress-bar .sub .step:not(.active) .icon {
        opacity: .3;
    }
}

@media (max-width: 760px) {
    .progress-bar .sub .divider {
        width: 50px;
    }
}

@media (max-width: 454px) {
    .progress-bar .sub .divider {
        width: 10px;
    }
    
}