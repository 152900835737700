.self-step.main-21 {
    margin-bottom: 0;
}

.super.global ~ .self-step.main-21 {
    padding-bottom: 0;
}

.self-step .global .questions.second-title {
    text-decoration: underline;
    margin-top: 110px;
}

.self-step .global colgroup > col {
    width: 25%;
}

.self-step .global colgroup > col.pre-choice {
    background-color: #F5F5F5;
}

.self-step > .global > .detail-offer {
    overflow-x: hidden;
}

.self-step .global table {
    border-spacing: 0;
    margin-top: 60px;
    margin-bottom: 60px;
    min-width: 1040px;
}

.self-step .global table thead tr th {
    padding: 0 20px;
    border-radius: 30px 30px 0 0;
    -webkit-border-radius: 30px 30px 0 0;
    -moz-border-radius: 30px 30px 0 0;
    -ms-border-radius: 30px 30px 0 0;
    -o-border-radius: 30px 30px 0 0;
}

.self-step .global table tfoot tr td {
    border-radius: 0 0 30px 30px;
    -webkit-border-radius: 0 0 30px 30px;
    -moz-border-radius: 0 0 30px 30px;
    -ms-border-radius: 0 0 30px 30px;
    -o-border-radius: 0 0 30px 30px;
}

.self-step .global table thead tr th .cta {
    background-color: #FFFFFF;
    color: var(--deep-pink);
    font-family: 'Akzid-Medium';
    white-space: nowrap;
    margin-bottom: 50px;
}

.self-step .global table thead tr th .cta.not-pre-choice {
    box-shadow: none;
    border: 2px solid #D8D8D8;
    color: #979797;
}

.self-step .global table thead tr th .title {
    font-size: 16pt;
    font-family: 'GtSuper-bold';
    margin: 45px 0 10px 0;
}

.self-step .global table thead tr th .description {
    color: #979797;
    font-size: 11pt;
    font-family: 'Akzid-Light';
    min-height: 52.5px;
}

.self-step .global table thead tr th .price {
    color: var(--deep-pink);
    font-family: 'Akzid-Medium';
    margin: 12px 0 15px 0;
    font-size: 15pt;
}

.self-step .global table thead tr th .price .little_exhibitor {
    vertical-align: super;
    font-size: 9pt;
    margin-right: 4px;
}

.self-step .global table tbody tr td {
    border-bottom: 2px solid #D8D8D8;
    text-align: left;
}

.self-step .global table tbody tr td p img.checkbox {
    width: 15px;
}

.self-step .global table tbody tr td.not-pre-choice {
    color: #979797;
}

.self-step .global table tbody tr td:nth-child(1) p {
    margin-left: 20px;
    margin-right: 20px;
}

.self-step .global table tbody tr td:not(:nth-child(1)) p {
    text-align: center;
}

.self-step .global table tbody tr td .title {
    font-family: 'Akzid-Medium';
    font-size: 15pt;
}

.self-step .global table tfoot tr td {
    padding: 40px 20px 45px 20px;
}

.self-step .global table tfoot tr td .cta {
    background-color: #FFFFFF;
    color: var(--deep-pink);
    font-family: 'Akzid-Medium';
    white-space: nowrap;
}

.self-step .global table tfoot tr td .cta.not-pre-choice {
    box-shadow: none;
    border: 2px solid #D8D8D8;
    color: #979797;
}

.self-step .global .offer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 100px;
    max-width: 1200px;
    margin: 100px auto
}

.self-step .global .offer .offer-main {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    /* z-index: 0; */
}

.self-step .global .offer .offer-main .cta.not-pre-choice {
    box-shadow: none;
    border: 2px solid #D8D8D8;
    color: #979797;
    background-color: #FFFFFF;
}

.self-step .global .offer .offer-main .offer-box {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 45px 20px;
    box-shadow: 0 0 16px -13px #000;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    margin-bottom: 30px;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    z-index: 1;
    cursor: pointer;
}

.self-step .global .offer .offer-main .offer-box .question-box {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    height: 16px;
}

.self-step .global .offer .offer-main .offer-box.pre-choice {
    box-shadow: 0 0px 9px 1px #FFDF00;
    border: 1px solid #FFDF00;
}

.self-step .global .offer .offer-main .offer-box .title {
    font-family: 'GtSuper-bold';
    font-size: 18pt;
}

.self-step .global .offer .offer-main .offer-box .price {
    font-family: 'Akzid-Medium';
    font-size: 25pt;
    color: var(--deep-pink);
    margin-top: 20px;
}

.self-step .global .offer .offer-main .offer-box .subprice {
    color: var(--deep-pink);
    height: 19px;
}

.self-step .global .offer .offer-main .offer-box .description {
    color: #979797;
    display: flex;
    flex-direction: row;
    font-size: 10pt;
    justify-content: center;
}

.self-step .global .offer .offer-main .offer-box ul {
    margin-block-start: 20px;
    list-style: none;
    padding-left: 0;
}

.self-step .global .offer .offer-main .offer-box ul li {
    background-image: url('/public/icon/big_check.svg');
    background-repeat: no-repeat;
    background-position: left 2px;
    padding-left: 30px;
    padding-bottom: 10px;
    text-align: left;
    font-size: 11pt;
}

.self-step .global .offer .offer-main .offer-box ul li.not {
    background-image: url('/public/icon/big_cross.svg');
}

.self-step .global .offer .offer-main .offer-box .reinsurance.important {
    background-color: #ff00621c;
    padding: 3px 14px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    font-size: 10pt;
    margin: 0 auto 5px auto;
    width: max-content;
    color: var(--deep-pink);
    position: absolute;
    top: -38px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

.self-step .global .offer .offer-main .offer-box > .question-box {
    margin-top: 5px;
}

.self-step .global .offer .offer-main .offer-box > .description > .question-box {
    transform: translateY(-1px);
    -webkit-transform: translateY(-1px);
    -moz-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    -o-transform: translateY(-1px);
}

@media not all and (pointer: coarse) {
    .self-step .global .offer .offer-main .offer-box.pre-choice:hover {
        transform: translateY(-5px);
        -webkit-transform: translateY(-5px);
        -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        -o-transform: translateY(-5px);
        box-shadow: 0 0 16px -6px #ff0063;
        border: 1px solid #ff0063;
    }
}

.self-step .global .offer .offer-main .offer-box:hover {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
}

.self-step .global .offer .offer-main .offer-box .price .space {
    letter-spacing: -6.5px;
}

.self-step .global .offer .offer-main .offer-box .price .space_ht {
    letter-spacing: -5.5px;
}

@media (max-width: 1334px) {
    .self-step .global .detail-offer {
        overflow-x: scroll;
    }
    .self-step .global .detail-offer {
        transform: rotateX(180deg);
        margin-top: 20px;
        -webkit-transform: rotateX(180deg);
        -moz-transform: rotateX(180deg);
        -ms-transform: rotateX(180deg);
        -o-transform: rotateX(180deg);
        width: calc(100% + 15px)
    }
    .self-step .global .detail-offer::-webkit-scrollbar {
        height: 10px;
        padding: 3px;
    }
    .self-step .global .detail-offer::-webkit-scrollbar-track {
        background: #D8D8D8;
        border-radius: 10px; /* Optional: rounds the corners of the thumb */
    }
    .self-step .global .detail-offer::-webkit-scrollbar-thumb {
        background: #FFFFFF;
        border: 2px solid #D8D8D8; /* Adds space around the thumb */
        border-radius: 10px; /* Optional: rounds the corners of the thumb */
        box-sizing: border-box; /* Ensures the border is included in the element's total width and height */
    }
    
    .self-step .global .detail-offer .offer_table {
        transform: rotateX(180deg);
        margin-bottom: 30px;
        -webkit-transform: rotateX(180deg);
        -moz-transform: rotateX(180deg);
        -ms-transform: rotateX(180deg);
        -o-transform: rotateX(180deg);
    }
}

@media (max-width: 1000px) {
    .self-step .global .offer {
        flex-wrap: wrap;
    }

    .self-step .global .offer .offer-main {
        max-width: 300px;
        margin-bottom: 50px;
    }
}

@media (max-width: 939px) {
    .self-step .global .offer .offer-main {
        max-width: 450px;
        margin-bottom: 50px;
    }
}

.exhibitor {
    vertical-align: super;
    font-size: 11pt;
    margin-right: 4px;
}

@media (max-width: 480px) {
    .self-step .global .detail-offer .offer_table {
        font-size: 11pt;
    }
}