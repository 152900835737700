.toggle_switch {
  display: flex;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch .input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFDF00;
    transition: .4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    right: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  .input:checked + .slider {
    background-color: #FFDF00;
  }

  .input:checked + .slider:before {
    transform: translateX(-26px);
    -webkit-transform: translateX(-26px);
    -moz-transform: translateX(-26px);
    -ms-transform: translateX(-26px);
    -o-transform: translateX(-26px);
}
  
  .active .slider {
    background-color: #FFDF00;
  }

  .active .slider:before {
    transform: translateX(-26px);
    -webkit-transform: translateX(-26px);
    -moz-transform: translateX(-26px);
    -ms-transform: translateX(-26px);
    -o-transform: translateX(-26px);
}