.form-login {
    width: 330px;
    border: 2px solid #D8D8D8;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    padding: 13px 11px;
    -webkit-appearance: none;
    appearance: none;
    background-image:url('/public/icon/cross.svg');
    background-repeat: no-repeat;
    background-size: 11px 11px;
    background-position: calc(100% - 16px);
}

.form-login.active {
    border: 2px solid #6FC467 !important;
    background-image:url('/public/icon/green_check.svg');
    background-size: 13px 11.5px;
}

.form-login.active:focus {
    outline: none !important;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #979797;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #979797;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #979797;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #979797;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    #979797;
}

::placeholder { /* Most modern browsers support this now. */
   color:    #979797;
}

.list {
    width: 100%;
    max-width: 742px;
}

.answers-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    column-gap: 10px;
}

.answers-row:last-child {
    margin-top: 30px;
}

.required {
    color: var(--deep-pink)
}

.answer {
    width: 100%;
    margin: 0 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.answer input {
    width: 100% !important;
    max-width: calc(100% - 28px) !important;
}

.cta {
    width: max-content;
    margin: 40px auto 20px auto;
}

.warning {
    color: red;
    margin-top: 40px
}

.spliter {
    width: 100%;
    max-width: 742px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}

.spliter .label {
    margin: 0 12px;
    color: #D8D8D8;
    transform: translateY(2px);
    -webkit-transform: translateY(2px);
    -moz-transform: translateY(2px);
    -ms-transform: translateY(2px);
    -o-transform: translateY(2px);  
}

.answer > .label {
    margin-left: 20px;
    margin-bottom: 5px;
    font-family: 'Akzid-Medium';
    white-space: nowrap;
    /* color: black; */
}

.line {
    width: calc(50% - 21px);
    height: 2px;
    border-bottom: 2px solid #D8D8D8;
}

.google-sign_in {
    display: flex;
    flex-direction: row;
    padding: 21px 20px;
    border: 2px solid #D8D8D8;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    margin-top: 30px;
    font-family: 'Akzid-Medium';
    cursor: pointer;
}

.google-sign_in:hover {
    background-color: #f7f7f7;
}

.google-sign_in img {
    width: 19px;
    height: 19px;
    margin-right: 15px;
}

@media (max-width: 645px) {
    .name_input .answer {
        margin: 0;
    }
    
    .name_input .answer input {
        width: calc(95% - 22px);
    }

    .answers-row:last-child {
        margin-top: 20px;
    }

    .answers-row.coords {
        flex-direction: column;
        row-gap: 20px;
    }

    .answer > .label {
        margin-left: 10px;
    }
}

.invalidForm {
    color: red !important;
}

.validForm {
    color: black !important;
}