@media (max-width: 790px) {
    .bigButton {
        padding: 15px 30px !important;
    }
    .bigButton .subtitle {
        margin-top: 10px !important;
    }
}

@media (max-width: 728px) {
    .bigButton {
        margin: 0 5px !important;
        padding: 15px 20px !important;
        width: calc(50% - 40px - 14px) !important;
        border-radius: 10px !important;
        -webkit-border-radius: 10px !important;
        -moz-border-radius: 10px !important;
        -ms-border-radius: 10px !important;
        -o-border-radius: 10px !important;
}
    .bigButton .subtitle {
        font-size: 10pt !important;
    }

}