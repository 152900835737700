.complementary {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
}

.complementary .pricing {
    border: 2px solid #FFDF00;
    box-shadow: 0 0px 9px 1px #FFDF00;
    padding: 50px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius:40px;
    -webkit-border-radius:40px;
    -moz-border-radius:40px;
    -ms-border-radius:40px;
    -o-border-radius:40px;
    width: 255px;
    max-width: 255px;
}

.complementary .pricing .promo_code {
    width: 100%;
    margin-bottom: 20px;
}

.complementary .pricing .promo_code .price {
    width: 100%;
    position: relative;
}

.complementary .pricing .promo_code .price .promo_code_validation {
    padding: 5px 10px;
    background-color: var(--deep-pink);
    box-shadow: 0 0px 9px 1px var(--deep-pink);
    color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    position: absolute;
    top: 9px;
    right: -3px;
    cursor: pointer;
}

.complementary .pricing .promo_code .price input {
    width: calc(100% - 74px);
    max-width: 330px;
    border: 2px solid #d8d8d8;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    padding: 13px 81px 13px 11px;
    transform: translateX(-11px);
}

.complementary .pricing .title { 
    font-family: 'GtSuper-medium';
    font-size: 16pt;
    margin-bottom: 60px;
    text-align: left;
    width: 100%;
}

.complementary .pricing .title.title_recap_1 {
    margin-bottom: 20px
}

.complementary .pricing .ht_total, .complementary .pricing .tva_total, .complementary .pricing .promo_total, .complementary .pricing .total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 11pt;
    width: 100%;
    padding: 0 0 5px 0;
}

.complementary .pricing .total {
    margin: 25px 0 30px 0;
    padding-bottom: 0;
    text-transform: uppercase;
    font-family: 'Akzid-Medium';
}

.complementary .pricing .cta {
    width: calc(100% - 96px);
    margin-bottom: 15px;
}

.complementary .pricing .unselectable, .complementary .price .promo_code_validation.unselectable{
    pointer-events: none;
    user-select: none;
    cursor: not-allowed;
}

.complementary .pricing .unselectable .cta, .complementary .pricing .unselectable.cta, .complementary .price .promo_code_validation.unselectable {
    background-color: #979797 !important;
    box-shadow: none !important;
    border: none;
    opacity: .4;    
}

.lines .line-items .upsell-items .upsell-item .description {
    margin-left: 20px;
    color: var(--deep-pink);
    margin-top: 8px;
}

.paymentChoiceNeeded {
    color: red;
    margin-bottom: 10px;
}

.complementary .pricing .alma {
    color: #979797;
}

.complementary .pricing .alma .label {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.complementary .pricing .alma .label span#alma_pricing {
    padding-left: 3px;
}

.complementary .pricing .alma img {
    width: 46px;
    margin-left: 5px;
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
}
.complementary .pricing .alma .x4 {
    color: white;
    background-color: #FA5022;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin-left: 5px;
    padding: 4px;
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
}

.complementary .pricing .alma .x4 div {
    transform: translateY(2px);
    -webkit-transform: translateY(2px);
    -moz-transform: translateY(2px);
    -ms-transform: translateY(2px);
    -o-transform: translateY(2px);
}

.complementary .pricing .security {
    font-family: 'Akzid-Medium';
}

.complementary .pricing .security img {
    transform: translateY(2px);
    -webkit-transform: translateY(2px);
    -moz-transform: translateY(2px);
    -ms-transform: translateY(2px);
    -o-transform: translateY(2px);
}

.complementary .pricing .reinsurance {
    margin: 40px 0 0px 0;
}

.complementary .pricing .reinsurance .important {
    background-color: #ff00621c;
    padding: 3px 14px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    font-size: 10pt;
    margin: 0 auto 5px auto;
    width: max-content;
    color: #FF0063;
}

.complementary .pricing .reinsurance .description {
    color: #979797;
    margin-top: 15px;
}

.complementary .contact {
    margin-top: 30px;
    padding: 30px 40px;
    text-align: left;
    background-color: #F5F5F5;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    width: 255px;
}

.complementary .contact .title {
    font-family: 'Akzid-Medium';
    font-size: 14pt;
    margin-bottom: 10px;
}

.complementary .contact .description {
    margin-bottom: 30px;
}

.complementary .contact .telephone {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.complementary .contact .telephone .number {
    display: flex;
    flex-direction: row;
    font-size: 17pt;
    color: var(--deep-pink);
}

.complementary .contact .telephone .number .icon.telephone {
    margin-right: 3px;
}

.complementary .contact .telephone .number a {
    text-decoration: none;
    color: var(--deep-pink);
    cursor: pointer;
    font-family: 'Akzid-Medium';
}

.complementary .contact .telephone .number img {
    width: 20px;
    margin-bottom: 5px;
}

.complementary .contact .telephone .free {
    font-size: 14pt;
    color: #979797;
    text-transform: uppercase;
    margin-left: 10px;
}


.complementary .conditions {
    margin: 20px 0;
}

.complementary .conditions .checkbox {
    accent-color: var(--deep-pink);
    border-radius: 5px;
    border: 1px solid var(--deep-pink);
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.complementary .conditions .description {
    font-size: 11pt;
}

.complementary .form_login {
    max-width: none !important;
}

.complementary .conditions .description label {
    cursor: pointer;
}

.complementary .conditions .description a {
    color: var(--deep-pink);
}

@media (max-width: 1022px) {
    .complementary {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .complementary .contact {
        margin-top: 0;
        height: min-content;
    }
    .complementary .pricing {
        margin-right: 20px;
        width: auto;
    }
}

@media (max-width: 746px) {
    .complementary {
        flex-direction: column;
    }

    .complementary .pricing {
        max-width: none;
        margin-bottom: 20px;
        margin-right: 0;
    }

    .complementary .contact {
        width: auto;
    }

    .complementary .contact .telephone {
        justify-content: flex-start;
    }
}

@media (max-width: 453px) {
    .complementary .contact .telephone {
        flex-direction: column;
        align-items: flex-start;
        
    }
}

@media (max-width: 362px) {
    .complementary .contact .telephone .telephone {
        width: 16px !important;
    }
    .complementary .contact .telephone .number {
        font-size: 13pt;
    }
    .complementary .contact .telephone .free {
        margin-left: 0;
    }
}