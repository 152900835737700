.main {
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px);
    margin: 30px 50px;
}

.main .timeline {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 40px;
    column-gap: 20px;
}

.main .timeline .step {
    margin: 0 10px;
    position: relative;
    background-color: #fff;
    width: 200px;
    padding: 30px;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    box-shadow: 0 0 13px -8px #000;
}

.main .timeline .step .number {
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background-color: #1F1F1F;
    color: white;
    padding: 5px;
    font-size: 10pt;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main .timeline .step img {
    width: 50px;
    margin-bottom: 20px;
}

.main .timeline .step .title {
    font-size: 18pt;
    padding-bottom: 10px;
}

.main .timeline .step .description {
    font-size: 13pt;
    margin-bottom: 0px;
}

.main .timeline .step .delay {
    background-color: #FAE14B;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    width: 45px;
    padding: 8px 3px;
    font-size: 8pt;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

.main .header {
    font-size: 30pt;
    font-family: GtSuper-bold;
}