.answer input {
    margin-bottom: 30px;
}

.answers_row {
    width: 100%;
}

.description {
    color: #979797 !important;
}