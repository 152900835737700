.subtitle {
    color: #979797;
    font-size: 12pt;
    margin-top: 20px;
}
.merchant-recap {
    margin: 60px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 60px;
    max-width: 1100px;
}
.content {
    display: flex;
    flex-direction: column;
}
.description {
    display: flex;
    flex-direction: column;
    padding: 30px 0 30px 0;
}

.description:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.main_name {
    font-family: 'GtSuper-medium';
    font-size: 16pt;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.stripePayment {
    padding: 30px 0;
}

.stripeCta {
    width: auto !important;
    padding: 16px 48px !important;
    border-radius: 40px !important;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    margin: 30px 0 !important;
}
.stripeCta.inactive {
    cursor: not-allowed;
    color: black;
    background-color: white;
    box-shadow: none;
    border: 2px solid var(--deep-pink);
    opacity: 0.5;
    pointer-events: none !important;
}
    
.stripeCta.active {
    cursor: pointer !important;
    color: white;
    background-color: var(--deep-pink);
    border: 2px solid var(--deep-pink);
    opacity: 1;
    pointer-events: all !important;
}

.stripeCta.active:hover {
    color: var(--deep-pink);
}

.title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: baseline;
}
.title .name {
    font-family: 'Akzid-Medium';
    font-size: 14pt;
    margin-right: 10px;
    text-align: left;
}
.key_point {
    display: flex;
    flex-direction: column;
}

span.bold {
    font-family: 'myriad-semibold';
}

.key_point ul {
    margin-block-start: 20px;
    list-style: none;
    margin-left: 20px;
    padding: 0;
    margin-block-end: 0;
}

.key_point ul li {
    background-image: url('/public/icon/big_check.svg');
    background-repeat: no-repeat;
    background-position: 0 2px;
    padding-left: 30px;
    padding-bottom: 10px;
    text-align: left;
    font-size: 11pt;
    color: #979797;
}

.payment_solutions {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.payment_solution {
    width: 100%;
}

.payment_solution label {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    width: calc(100% - 30px);
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin: 10px 0;
    cursor: pointer;
    position: relative;
}

.paypalButton {
    margin-top: 20px;
}

.payment_solution .listSepa.paypalChoice {
    width: 100%;
    max-width: none;
}

.payment_solution label.almaChoice, .payment_solution label.sepaChoice, .payment_solution label.paypalChoice {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 30px);
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin: 10px 0;
    cursor: pointer;
    position: relative;
}

.payment_solution label .payment_choice {
    width: 100% ;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
}

.payment_solution label .payment_solution_spotlight {
    position: absolute;
    width: max-content;
    padding: 5px 10px;
    border-radius: 8px;
    background-color: var(--gold);
    border: 1px solid var(--gold);
    box-shadow: 0 0px 9px 1px var(--gold);
    top: 0;
    font-size: 10pt;
    left: 50%;
    transform: translateX(-50%) translateY(-60%);
    -webkit-transform: translateX(-50%) translateY(-60%);
    -moz-transform: translateX(-50%) translateY(-60%);
    -ms-transform: translateX(-50%) translateY(-60%);
    -o-transform: translateX(-50%) translateY(-60%);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.payment_solution label .text {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

.payment_solution .logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment_solution .logo img {
    height: 18px;
}

.payment_solution .label input[type="radio"]  {
    display: inline-block;
    width: 15px;
    min-width: 15px;
    height: 15px;
    min-height: 15px;
    margin-right: 10px;
    border-radius: 10px;
    border: 1px solid #ddd;
    margin-right: .5rem;
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
}

.security.choice {
    display: flex;
    justify-content: center;
    align-items: normal;
    /* margin: 10px 0 5px 0; */
}

.security {
    font-size: 13pt;
    display: flex;
    justify-content: center;
}

.security img {
    margin-right: 8px;
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
}

.loader {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: rgba(215, 215, 215, 0.604);
    z-index: 999999;
}

.pop_up {
    display: none;
    position: fixed;
    font-size: 14pt;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: black;
    background-color: rgba(0, 0, 0, 0.26);
    z-index: 99999999;
    top: 0;
    text-align: center;
    left: 0;
}

.pop_up .cross {
    font-size: 15pt;
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: white;
    color: black;
    padding: 10px;
    cursor: pointer;
    border: 1px solid rgb(161, 161, 161);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.main_pop_up {
    margin: 20px;
    font-size: 12pt;
    background-color: white;
    padding: 35px 80px;
    border-radius: 40px;
    width: calc(100% - 200px);
    max-width: 700px;
}

.listSepa {
    max-width: max-content;
    margin: 0 auto;
    display: none;
}

.listSepa .information {
    margin-top: 15px;
    padding: 10px;
    background-color: #ffffd4;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-size: 11pt;
}

.listSepa .mainSepaChoice {
    display: flex;
    justify-content: space-evenly;
    column-gap: 6px;
    flex-wrap: wrap;
    row-gap: 6px;
    margin-top: 20px;
}

.listSepa .mainSepaChoice .cta.sepaSubChoice {
    width: min-content;
    font-size: 9pt;
    border-radius: 17px;
    padding: 15px;
    width: 120px;
    text-align: center;
    }
    
.listSepa .mainSepaChoice .cta.sepaSubChoice div {
    pointer-events: none;
    user-select: none;
}

.listSepa .mainSepaChoice .cta.sepaSubChoice.inactive {
    background-color: white;
    border: 2px solid #ffe000;
    opacity: 0.5;
    pointer-events: all !important;
}

.listSepa .mainSepaChoice .cta.sepaSubChoice.active {
    background-color: #ffe000;
    border: 2px solid #ffe000;
    opacity: 1;
    pointer-events: none !important;
}

.description.error {
    background-color: red;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 20px 40px;
    margin-top: 30px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}

@media (max-width: 1022px) {
    .merchant-recap {
        flex-direction: column;
    }
}

.answers-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    column-gap: 10px;
}

.answers-row:last-child {
    margin-top: 30px;
}

.required {
    color: var(--deep-pink)
}

.form-login {
    width: 330px;
    border: 2px solid #D8D8D8;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    padding: 13px 11px;
    -webkit-appearance: none;
    appearance: none;
    background-image:url('/public/icon/cross.svg');
    background-repeat: no-repeat;
    background-size: 11px 11px;
    background-position: calc(100% - 16px);
}

.form-login.active {
    border: 2px solid #6FC467 !important;
    background-image:url('/public/icon/green_check.svg');
    background-size: 13px 11.5px;
}

.form-login.active:focus {
    outline: none !important;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #979797;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #979797;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #979797;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #979797;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    #979797;
}

::placeholder { /* Most modern browsers support this now. */
   color:    #979797;
}

.answer {
    width: 100%;
    margin: 0 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.answer input {
    width: 100% !important;
    max-width: calc(100% - 28px) !important;
}

.answer > .label {
    margin-left: 20px;
    margin-bottom: 5px;
    font-family: 'Akzid-Medium';
    white-space: nowrap;
    color: black;
}

.connection {
    margin-bottom: 30px;
}

.iframeMain {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.mandateBox {
    padding: 20px;
    background-color: rgb(239, 238, 236);
    border-radius: 10px;
    max-width: 900px;
    height: 100vh;
    width: 100%;
}

.mandateBox iframe {
    border: none;
    height: 100%;
}

@media (max-width: 645px) {
    .name_input .answer {
        margin: 0;
    }
    
    .name_input .answer input {
        width: calc(95% - 22px);
    }

    .answers-row:last-child {
        margin-top: 20px;
    }

    .answers-row.coords {
        flex-direction: column;
        row-gap: 20px;
    }

    .answer > .label {
        margin-left: 10px;
    }
}