.cta {
    margin: 40px auto 20px auto;
}

.button {
    position: relative;
}

.bold {
    font-family: 'Akzid-Medium';
}