.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: rgba(215, 215, 215, 0.604);
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-content: center;
}

.loaderText {
    color: black;
    background-color: white;
    padding: 30px 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    width: 300px;
    max-width: calc(100vw - 80px);
}

.subtitle {
    color: #979797;
    font-size: 12pt;
    margin-top: 20px;
}

.merchant-recap {
    margin: 60px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 60px;
    max-width: 1100px;
}

.iframeMain {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.content {
    display: flex;
    flex-direction: column;
}

.mandateBox {
    padding: 20px;
    background-color: rgb(239, 238, 236);
    border-radius: 10px;
    max-width: 900px;
    height: 100vh;
    width: 100%;
}

.contractBox {
    max-width: none;
}

.mandateBox iframe {
    border: none;
    height: 100%;
}
