.legal_name {
    color: var(--deep-pink);
}

.button {
    position: relative;
}

.sub_details {
    font-size: 10pt;
    font-family: 'Akzid-Reg';
    margin: 0 auto 30px auto;
    max-width: 630px;
    color: #979797;
}

.sub_details .arrow::before {
    content: '';    
    display: inline-flex;
    width: 15px;
    height: 10px;
    margin-right: 5px;
    background: url('/public/icon/arrow.svg') no-repeat center center;
    background-size: contain;
}