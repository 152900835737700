.subtitle {
    color: #979797;
    font-size: 12pt;
    margin-top: 20px;
}

.bold {
    font-size: 14pt;
    margin: 30px 0;
    color: black;
}

.legal_name {
    color: var(--deep-pink);
}