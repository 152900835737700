.navbar {
    width: 100%;
}

.navbar .sub {
    width: calc(100% - 60px);
    padding: 30px 30px;
}

@media (max-width: 800px) {
    .navbar .sub {
        padding: 15px 30px;
    }
}

.navbar .sub .information {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navbar .sub .information .logo img {
    width: 165px;
}

.navbar .sub .information .cta_phone {
    display: flex;
    flex-direction: row;
}

.navbar .sub .information .cta_phone .icon {
    width: 1em;
    height: 1em;
    margin-right: 5px;
}

.navbar .sub .information .cta_phone .number.standard {
    display: flex;
    flex-direction: row;
    margin-right: 8px;
}

.navbar .sub .information .cta_phone .number.standard span {
    text-decoration: underline;
}

.navbar .sub .information .cta_phone .number.standard ion-icon {
    margin-right: 8px;
}

@media (max-width: 484px) {
    .navbar .sub {
        width: calc(100% - 60px);
    }
    .navbar .sub .information .cta_phone {
        flex-direction: column;
        text-align: center;
    }
}

@media (max-width: 400px) {
    .navbar .sub .information .logo img {
        width: 110px;
    }
    
}