.big-button {
    padding: 18px 45px !important;
    border-radius: 18px !important;
    -webkit-border-radius: 18px !important;
    -moz-border-radius: 18px !important;
    -ms-border-radius: 18px !important;
    -o-border-radius: 18px !important;
}

.later {
    margin-top: 20px;
}