.subtitle {
    color: #979797;
    font-size: 12pt;
    margin-top: 20px;
}

.merchant-recap {
    margin: 60px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 60px;
    max-width: 1100px;
}

.merchant-recap .lines {
    display: flex;
    flex-direction: column;
}

.merchant-recap .line-items {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #D8D8D8;
    padding: 30px 0 30px 0;
}

.merchant-recap .line-items:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.merchant-recap .line-items .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: baseline;
}

.merchant-recap .line-items .title .name {
    font-family: 'Akzid-Medium';
    font-size: 14pt;
    margin-right: 10px;
    text-align: left;
}

.legal_name {
    color: var(--deep-pink);
}

.merchant-recap .line-items .title .edit {
    color: #979797;
    font-size: 12pt;
    text-decoration: underline;
    cursor: pointer;
}

.merchant-recap .line-items .line-item {
    display: flex;
    flex-direction: column;
}

.merchant-recap .line-items .line-item .line-information {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14pt;
    font-family: 'Akzid-Medium';
}

.merchant-recap > div.lines > div:nth-child(3) {
    padding-top: 0;
}

.merchant-recap .line-items .line-item .line-information .name {
    font-family: 'GtSuper-bold';
    text-align: left;
}

.merchant-recap .line-items .line-item .line-information .pricing {
    color: var(--deep-pink);
    margin-left: 10px;
    white-space: nowrap;
}

.merchant-recap .line-items .line-item .line-information .pricing .exhibitor {
    vertical-align: super;
    font-size: 9pt;
    margin-right: 4px;
}

.merchant-recap .line-items .line-item ul {
    margin-block-start: 20px;
    list-style: none;
    margin-left: 20px;
    padding: 0;
    margin-block-end: 0;
}

.merchant-recap .line-items .line-item ul li {
    background-image: url('/public/icon/big_check.svg');
    background-repeat: no-repeat;
    background-position: 0 2px;
    padding-left: 30px;
    padding-bottom: 10px;
    text-align: left;
    font-size: 11pt;
    color: #979797;
}

.merchant-recap .line-items .line-item .description {
    color: #979797;
    text-align: left;
    margin: 20px 10px 20px 20px;
    font-size: 11pt;
}

.merchant-recap .cta_global .description {
    margin-top: 10px;
    color: #979797;
    font-size: 11pt;
}

.merchant-recap .lines .line-items .title .name .important {
    text-decoration: underline;
    margin-bottom: 15px;
}

.merchant-recap .lines .line-items .upsell-items {
    display: flex;
    flex-direction: column;
}

.merchant-recap .lines .line-items .upsell-items .upsell-item .item-content.community_management {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.merchant-recap .lines .line-items .upsell-items .upsell-item {
    margin: 15px 0;
    background-color: #fff;
    box-shadow: 0 0 16px -13px #000;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 30px 40px;
}

.merchant-recap .lines .line-items .upsell-items .upsell-item .details {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.merchant-recap .lines .line-items .upsell-items .upsell-item .details .title {
    font-family: 'Akzid-Medium';
    text-align: left;
}

.merchant-recap .lines .line-items .upsell-items .upsell-item .details .description {
    color: #979797;
    text-align: left;
    margin-top: 10px;
}

.merchant-recap .lines .line-items .upsell-items .upsell-item .cta {
    background-color: #FFFFFF;
    color: var(--deep-pink);
    font-family: 'Akzid-Medium';
    white-space: nowrap;
    margin-left: 20px;
    min-width: calc(243.22px - 48px - 48px);
}

.merchant-recap .lines .line-items .upsell-items .upsell-item .cta.community_management_cta {
    min-width: calc(280.44px - 48px - 48px);
}

.merchant-recap .lines .line-items .more {
    text-decoration: underline;
    color: #979797;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.merchant-recap .lines .line-items .upsell-continuation {
    display: none;
}

.merchant-recap .lines .line-items .upsell-continuation .upsell-items .upsell-item-global .upsell-item .item-supplemant {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 25px;
}

.merchant-recap .lines .line-items .upsell-continuation .upsell-items .upsell-item-global .upsell-item .details .description, .merchant-recap .lines .upsell-items .details .description {
    margin-left: 0 !important; 
}

.merchant-recap .lines .line-items .upsell-continuation .upsell-items .upsell-item-global .upsell-item .item-supplemant .media {
    margin: 5px 30px 5px 30px;
    color: #979797;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: 100px;
    justify-content: center;
}

.merchant-recap .lines .line-items .upsell-continuation .upsell-items .upsell-item-global .upsell-item .item-supplemant .media::after {
    content: '';    
    display: inline-flex;
    width: 15px;
    height: 15px;
    margin-left: 0px;
    margin-top: 1px;
    background: url('/public/icon/add.svg') no-repeat center center;
    background-size: contain;
}
.merchant-recap .lines .line-items .upsell-continuation .upsell-items .upsell-item-global .upsell-item .item-supplemant .media.active::after {
    content: '';    
    display: inline-flex;
    width: 12px;
    height: 12px;
    margin-top: 2px;
    background: url('/public/icon/big_check.svg') no-repeat center center;
    background-size: contain;
}

.merchant-recap .lines .line-items .upsell-continuation .upsell-items .upsell-item-global .upsell-item .item-supplemant .media.active {
    color: var(--deep-pink);
}

.merchant-recap .lines .line-items .upsell-continuation .upsell-items .upsell-item-global .upsell-item .item-supplemant .media .label {
    padding-right: 10px;
}

.upsellShow {
    display: block !important;
}

.merchant-recap .social_networks {
    color: #979797;
    text-align: left;
    margin: 0px 0 20px 20px;
    font-size: 11pt;
}

.merchant-recap .lines .line-item .line-information .pricing .special_pricing {
    display: flex;
    flex-direction: column;
}

.merchant-recap .lines .line-item .line-information .pricing .first_year {
    font-size: 9pt;
}

.merchant-recap .ldm_argument {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.merchant-recap .ldm_argument .ldm {
    margin-top: 20px;
    margin-left: 10px;
}
.merchant-recap .ldm_argument .ldm > .cta {
    padding: 10px 15px;
    font-size: 10pt;
    text-transform: none;
}

.merchant-recap .ldm_argument .ldm > .cta svg {
    width: 10px;
    height: 10px;
    margin-left: 5px;
    transform: translateY(1px);
    -webkit-transform: translateY(1px);
    -moz-transform: translateY(1px);
    -ms-transform: translateY(1px);
    -o-transform: translateY(1px);
}

.merchant-recap .upsell_lines {
    max-width: calc(100% - 255px - 60px - 80px);
}

.merchant-recap .payLater {
    margin: 0px 20px 20px 20px;
    font-size: 11pt;
    text-align: left;
}

@media (max-width:1250px ) {
    .merchant-recap .lines .line-items .upsell-items .upsell-item, .merchant-recap .lines .line-items .upsell-items .upsell-item .item-content.community_management {
        flex-direction: column;
    }
    .merchant-recap .lines .line-items .upsell-items .upsell-item .cta {
        margin-top: 20px;
        margin-left: 0;
    }
}

@media (max-width: 1022px) {
    .merchant-recap {
        flex-direction: column;
    }
    .merchant-recap .lines .line-items .upsell-items .upsell-item, .merchant-recap .lines .line-items .upsell-items .upsell-item .item-content.community_management {
        flex-direction: row;
    }
    .merchant-recap .lines .line-items .upsell-items .upsell-item .details {
        margin-right: 15px;
    }
    .merchant-recap .upsell_lines {
        max-width: calc(100%);
    }
}

@media (max-width: 746px) {
    .merchant-recap .lines .line-items .upsell-continuation .upsell-items .upsell-item-global .upsell-item .item-supplemant .media {
        margin: 5px 15px;
    }
}

@media (max-width: 690px) {
    .merchant-recap .lines .line-items .upsell-items .upsell-item, .merchant-recap .lines .line-items .upsell-items .upsell-item .item-content.community_management {
        flex-direction: column;
    }
}

@media (max-width: 408px) {
    .merchant-recap .lines .line-items .upsell-items .upsell-item .cta {
        padding: 12px 20px;
        font-size: 10pt;
    }
}

@media (max-width: 369px) {
    .merchant-recap .lines .line-items .upsell-continuation .upsell-items .upsell-item-global .upsell-item .item-supplemant .media {
        margin: 5px 0;
    }
}

@media (max-width: 362px) {

    .merchant-recap .lines .line-items .upsell-items .upsell-item .cta {
        padding: 12px 12px;
    }
    .merchant-recap .ldm_argument {
        flex-direction: column;
    }
}