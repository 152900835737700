.later {
    margin-top: 20px;
}

.multiform {
    flex-wrap: nowrap !important;
}

@media (max-width: 1379px) {
    .multiform {
        flex-wrap: wrap !important;
    }
}