.button {
    padding: 20px 40px !important;
    width: calc(343px - 90px) !important;
    border-radius: 20px !important;
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    -ms-border-radius: 20px !important;
    -o-border-radius: 20px !important;
    position: relative;
    height: min-content;
}

.form-login {
    width: calc(343px - 32px);
    height: calc(68px - 26px - 4px);
    max-height: 68px;
    border: 2px solid #D8D8D8;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 13px 11px;
    position: absolute;
    bottom: calc(-100% + 26px - 20px - 22px);
}